import { useToast } from '@/components/ui/toast/use-toast'
import { defineStore } from 'pinia'

interface UserState {
  isFirstOpen: boolean
  name: string
  readingList: string[]
  onboardingCompleted: boolean
}

const baseUrl = 'API_URL'
const { toast } = useToast()

export const useUserStore = defineStore({
  id: 'user-stores',
  // persist: true,
  state: (): UserState => {
    return {
      isFirstOpen: true,
      name: 'User',
      readingList: [],
      onboardingCompleted: false,
    }
  },
  actions: {
    setName(name: string) {
      this.$state.name = name
    },
    async saveEntrytoReadingList(entryId: string) {
      // push to reading list and prevent duplicates
      if (!this.$state.readingList.includes(entryId)) {
        this.$state.readingList.push(entryId)
        await this.setBookmarked(entryId)
      }

      toast({
        title: 'Entry saved to reading list',
        description: 'You can access it from the menu',
      })
    },
    async removeEntryfromReadingList(entryId: string) {
      // remove from reading list
      this.$state.readingList = this.$state.readingList.filter(
        (id: string) => id !== entryId,
      )

      toast({
        description: 'Entry removed from reading list',
      })
    },
    isInReadingList(entryId: string) {
      return this.$state.readingList.includes(entryId)
    },
    setOnboardingCompleted(status: boolean) {
      this.$state.onboardingCompleted = status
    },
    async setDetailViewed(itemId: string, recommId?: string) {
      try {
        console.log('Updating user detail view:', itemId)
        const body: any = { itemId }
        if (recommId) {
          body.recommId = recommId
        }
        await $fetch(`/api/recommendations/update`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        })
      }
      catch (error) {
        console.error('Error updating user detail view:', error)
      }
    },
    async setBookmarked(itemId: string) {
      try {
        console.log('Updating user bookmark:', itemId)
        await $fetch(`/api/recommendations/bookmark`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ itemId }),
        })
      }
      catch (error) {
        console.error('Error updating user bookmark:', error)
      }
    },
  },
  getters: {
    getName: state => state.name,
    getReadingList: state => state.readingList,
  },
  persist: {
    storage: persistedState.localStorage,
    beforeRestore: (context) => {
      // console.log('Before hydration...', context)
    },
    afterRestore: (context) => {
      // console.log('After hydration...')
    },
  },
})
